window.bioEp = {
  // Private variables
  popupEl: {},
  closeBtnEl: {},
  shown: false,
  forceShown: false,
  overflowDefault: "visible",

  // Popup options
  delay: 2,
  showOnDelay: false,
  cookieExp: 1,

  // Object for handling cookies, taken from QuirksMode
  // https://www.quirksmode.org/js/cookies.html
  cookieManager: {
    // Create a cookie
    create: function(name, value, days) {
      var expires = "";

      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      }

      document.cookie = name + "=" + value + expires + "; path=/";
    },

    // Get the value of a cookie
    get: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }

      return null;
    },

    // Delete a cookie
    erase: function(name) {
      this.create(name, "", -1);
    }
  },

  // Handle the exitintent_shown cookie
  // If present and true, return true
  // If not present or false, create and return false
  checkCookie: function() {
    // Handle cookie reset
    if (bioEp.cookieExp <= 0) {
      bioEp.cookieManager.erase("exitintent_shown");
      return false;
    }

    // If cookie is set to true
    if (bioEp.cookieManager.get("cookie_notice_accepted") === "true") {

      if (bioEp.cookieManager.get("exitintent_shown") === "true") { return true; }

      // Otherwise, create the cookie and return false
      bioEp.cookieManager.create("exitintent_shown", "true", bioEp.cookieExp);

      return false;
    }
    return false;
  },


  // Show the popup
  showPopup: function() {

    if (bioEp.shown === true) {
      if ( bioEp.forceShown === false) {
        return;
      }
    }

    bioEp.popupEl.classList.add('active');

    // Save body overflow value and hide scrollbars
    bioEp.overflowDefault = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    bioEp.shown = true;
    bioEp.forceShown = false;

  },

  // Hide the popup
  hidePopup: function() {
    bioEp.popupEl.classList.remove('active');
    document.body.style.overflow = this.overflowDefault;
  },

  // Event listener initialisation for all browsers
  addEvent: function(obj, event, callback) {
    if (obj.addEventListener)
      obj.addEventListener(event, callback, false);
    else if (obj.attachEvent)
      obj.attachEvent("on" + event, callback);
  },

  // Load event listeners for the popup
  loadEvents: function() {
    // Track mouseout event on document
    this.addEvent(document, "mouseout", function(e) {
      e = e ? e : window.event;
      var from = e.relatedTarget || e.toElement;

      // Reliable, works on mouse exiting window and user switching active program
      if (!from || from.nodeName === "HTML")
        bioEp.showPopup();
    });
  },

  // Set user defined options for the popup
  setOptions: function(opts) {
    this.delay = (typeof opts.delay === 'undefined') ? this.delay : opts.delay;
    this.showOnDelay = (typeof opts.showOnDelay === 'undefined') ? this.showOnDelay : opts.showOnDelay;
    this.cookieExp = (typeof opts.cookieExp === 'undefined') ? this.cookieExp : opts.cookieExp;
  },

  // Ensure the DOM has loaded
  domReady: function(callback) {
    (document.readyState === "interactive" || document.readyState === "complete") ? callback(): this.addEvent(document, "DOMContentLoaded", callback);
  },

  // Initialize
  init: function(opts) {
    // Handle options
    if (typeof opts !== 'undefined')
      this.setOptions(opts);

    // Once the DOM has fully loaded
    this.domReady(function() {

      document.getElementById('shadow').addEventListener("click", function() {
        bioEp.hidePopup();
      });

      document.getElementById('ei-close').addEventListener("click", function() {
        bioEp.hidePopup();
      });

      // Add the popup
      bioEp.popupEl = document.getElementById("exit-intent");

      // Handle the cookie
      if (bioEp.checkCookie()) return;

      // Load events
      setTimeout(function() {
        bioEp.loadEvents();

        if (bioEp.showOnDelay)
          bioEp.showPopup();
      }, bioEp.delay * 1000);
    });
  }
};

bioEp.init();